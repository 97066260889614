import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  btnCheck: 'Check it Out',
  btnGetAdvice: 'Get free advice now',
  btnBack: 'Case study - ',
  welcomeTitle: 'Welcome to',
  challenge: 'Challenge',
  solution: 'Solution',
  approach: 'Approach',
  googleplayLink: 'https://play.google.com/store/apps/details?id=de.weekengo&hl=en',
  functionalitiesTitle: 'Main functionalities',
  featuresTitle: 'The features',
  mainFeaturesTitle: 'Main features',
  keyFunctionsTitle: 'Key functions',
  roadmapTitle: 'Project roadmap',
  roadmapWorkshop: 'Initial workshop',
  roadmapConception: 'Conception',
  roadmapDevelopment: 'Iterative development',
  roadmapRelease: 'First Release',
  workflow: 'Worklow',
  brandsTitle: 'We create brands',
  featuresDoneTitle: 'Features done by @freshcells',
  brandingDoneTitle: 'Branding done by @freshcells',
  brandingTitle: 'Branding',
  techTitle: 'Technology we used',
  techPerfomance: 'High Perfomance',
  techScalable: 'Absloutly scalabe',
  techFlexible: 'Flexible',
  techArt: 'State of the Art',
  designTitle: 'Responsive design',
  designText: 'All our products are built responsively as standard, so they look great on any device. We use innovative UI elements to create unique products.',
  designItemInnovative: 'Innovative UI elements',
  designItemDevices: 'Designed for all devices',
  knowTitle: 'Get to know the product',
  feedbackTitle: 'Client feedback',
  reviewesTitle: 'Customer reviews',
  checkTitle: 'See it in action',
  checkText: 'If you get interested into this project hit the button and check it live.',
  logoTitle: 'Logo',
  colorsTitle: 'Colors',
  brandRecognitionTitle: 'Brand with recognition value',
  recognitionTitle: 'Recognition value',
  casesOverviewTitle: 'Our <b>Case Studies</b>',
  casesOverviewDescription: 'Some hand selected, award winning success stories we are very proud of. Learn how we found solutions for typical business challenges.'
})
