// @flow

import React from 'react'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'

import Button from '../Button'

import messages from './FloatingCTA.lang'
import baseStyles from './FloatingCTA.scss'

type Props = {
  img?: any,
  text?: any,
  buttonLabel?: string,
  ...StyleProps
}

const FloatingCTA = ({ img, text, buttonLabel, styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      {img && <div className={styles.imgWrapper}>{img}</div>}
      <div className={styles.content}>
        <div
          dangerouslySetInnerHTML={{ __html: formatMessage(text || messages.contactText) }}
          className={styles.text}
        />
        <div className={styles.link}>
          <Link to="/contact/">
            <Button styles={styles.btn} tag="span" color="accent" block>
              {formatMessage(buttonLabel || messages.contactBtn)}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(FloatingCTA)
