// @flow

import React from 'react'
import classNames from 'classnames'
import { Parallax } from 'react-scroll-parallax'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Button from '../../basic/Button'
import { Container } from '../../basic/Grid'
import Scrollindicator from '../../basic/Scrollindicator'
import BackgroundOverlay from '../../basic/BackgroundOverlay'

import messages from './CaseMainTeaser.lang'
import baseStyles from './CaseMainTeaser.scss'

type Props = {
  title?: any,
  description?: any,
  logo?: any,
  logoBg?: string,
  modeSettings?: Object,
  backLink?: string,
  ...StyleProps
}

const CaseMainTeaser = ({ modeSettings, title, description, logo, logoBg, backLink, styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classNames(styles.root, modeSettings && modeSettings.mode === 'dark' && styles.isDark)}>
      <BackgroundOverlay styles={styles.overlay} {...modeSettings} />
      <Container className={styles.container} fluid>
        <div className={styles.backLinkWrapper}>
          {backLink && <Link to={backLink}>
            <Button styles={styles.backLink} tag="span" color="link" icon="icon-arrow_03-left-medium">
              {formatMessage(messages.backBtnLabel)}
            </Button>
          </Link>}
        </div>
        {(title || description || logo) && (
          <Parallax y={[-10, 10]} className={styles.parallaxWrapper}>
            <div>
            {title &&
              <h1 dangerouslySetInnerHTML={{ __html: title }}  className={styles.title}
              />
            }
            {description &&
              <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: description }} />
            }
            {logo &&
              <div className={styles.logoWrapper}>
                <div className={classNames(styles.logo, logoBg && styles['is-' + logoBg])}>{logo}</div>
              </div>
            }
            </div>
          </Parallax>
        )}
      </Container>
      <Scrollindicator light absolute />
    </div>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseMainTeaser)
