import { defineMessages } from 'react-intl'

/* eslint-disable max-len */
export default defineMessages({
  contactText: {
    defaultMessage: 'Do you have any questions? Please do not hesitate to contact us.'
  },
  contactBtn: {
    defaultMessage: 'To the contact form'
  }
})
