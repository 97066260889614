// @flow

import React from 'react'
import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import baseStyles from './BackgroundOverlay.scss'

/* eslint-disable max-len */
const textureLight = (
  <StaticImage
    src="../../../images/casestudy/smm/texture-white.png"
    alt=""
    objectFit="contain"
    className="imageCover"
  />
)
const textureDark = (
  <StaticImage
    src="../../../images/casestudy/smm/texture-primary.png"
    alt=""
    objectFit="contain"
    className="imageCover"
  />
)
/* eslint-enable max-len */

type Props = {
  bgColour?: string,
  bgImg?: any,
  texture?: string,
  ...StyleProps
}

const BackgroundOverlay = ({ bgColour, bgImg, texture, styles }: Props) => {
  return (
    <div className={classNames(styles.root, bgColour && styles['is-' + bgColour])}>
      <div className={styles.overlay} />
      {bgImg && bgImg}
      {texture && (
        <div className={styles.texture}>
          {texture === 'light' && textureLight}
          {texture === 'dark' && textureDark}
        </div>
      )}
    </div>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(BackgroundOverlay)
