// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import classNames from 'classnames'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import Button from '../../../components/basic/Button'
import { Container } from '../../../components/basic/Grid'
import messages from '../CaseStudy.lang'
import baseStyles from './CaseActionTeaser.scss'

type Props = {
  ...StyleProps,
  link?: string,
  linkTitle?: string,
  img: string,
  title: string,
  preTitle?: string,
  text: string,
  statement?: boolean,
  linkLocal?: boolean,
  mirorred?: boolean,
  noBackground?: boolean,
  icon?: string,
  ctaIconRight?: boolean,
  isDarkTeaser?: boolean
}

const CaseActionTeaser = ({
  styles,
  link,
  linkTitle,
  img,
  preTitle,
  title,
  text,
  statement,
  mirorred,
  noBackground,
  icon,
  linkLocal,
  ctaIconRight,
  isDarkTeaser
}: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classNames(styles.root, mirorred && styles.mirorred)}>
      <Container className={styles.container} fluid>
        <div className={styles.imageWrap}>
          <div className={styles.imageContainer}>
            <ScrollAnimation animateIn={mirorred ? 'fadeInRight' : 'fadeInLeft'} animateOnce>
              {img}
            </ScrollAnimation>
          </div>
        </div>
        <ScrollAnimation animateIn={mirorred ? 'fadeInLeft' : 'fadeInRight'} animateOnce>
          <div className={classNames(styles.panel, noBackground && styles.noBackground)}>
            {preTitle ? (
              <div
                className={classNames(icon ? styles.preTitleIcon : styles.preTitle, isDarkTeaser && styles.darkTeaser)}
              >
                {icon ? <span className={classNames(styles.icon, icon)} /> : null}
                {formatMessage(preTitle)}
              </div>
            ) : null}
            <h2
              className={classNames(styles.title, statement && styles.statement, isDarkTeaser && styles.darkTeaser)}
              dangerouslySetInnerHTML={{ __html: formatMessage(title) }}
            />
            <p className={styles.description}>{formatMessage(text)}</p>
            {linkLocal ? (
              <Link to={link} className={styles.btnLink}>
                <Button
                  styles={styles.btn}
                  tag="span"
                  color="accent"
                  iconRight={ctaIconRight ? 'icon-arrow_03-right-medium' : undefined}
                >
                  {linkTitle || formatMessage(messages.btnCheck)}
                </Button>
              </Link>
            ) : (
              <a href={link} target="_blank" rel="noopener noreferrer" className={styles.btnLink}>
                <Button
                  styles={styles.btn}
                  tag="span"
                  color="accent"
                  iconRight={ctaIconRight ? 'icon-arrow_03-right-medium' : undefined}
                >
                  {linkTitle || formatMessage(messages.btnCheck)}
                </Button>
              </a>
            )}
          </div>
        </ScrollAnimation>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseActionTeaser)
